<template>
  <section id="login" class="fill-height">
    <v-container
      style="height: inehit"
      class="fill-height px-7 px-md-5 justify-center"
    >
      <v-row justify="space-around" align="center">
        <v-col cols="12" md="5" class="text-center">
          <h1>Grand Flex</h1>
          <!-- <v-img
                        :src="require('../../assets/brand/logo1.png')"
                        alt="PBXBerry brand"
                        width="45vh"
                        class="mx-auto"
                        contain
                    ></v-img> -->
          <!-- <h1 class="mt-7 mt-md-12 headline">Bienvenido</h1> -->
          <p class="mt-8 mb-6">
            ¡Bienvenido! Introduzca sus credenciales para acceder.
          </p>
          <ValidationObserver ref="obs" v-slot="{ passes }">
            <v-form @submit.prevent="passes(Handlerlogin)">
              <ValidationProvider
                name="usuario"
                rules="email|required"
                v-slot="{ errors }"
              >
                <v-text-field
                  required
                  filled
                  rounded
                  label="Usuario"
                  name="user"
                  prepend-inner-icon="mdi-account"
                  type="text"
                  color="secondary"
                  v-model="email"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>

              <ValidationProvider
                name="contraseña"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  required
                  filled
                  rounded
                  label="Contraseña"
                  name="password"
                  prepend-inner-icon="mdi-lock"
                  :append-icon="
                    showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                  "
                  :type="showPassword ? 'text' : 'password'"
                  color="secondary"
                  v-model="password"
                  :error-messages="errors"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </ValidationProvider>

              <v-row no-gutters class="mx-1" justify="space-between">
                <v-col cols="12" md="auto">
                  <!-- <v-checkbox
                    class="mt-0"
                    label="Recuérdame"
                    color="secondary"
                  ></v-checkbox> -->
                </v-col>
                <v-col cols="12" md="auto" class="d-none d-md-block mt-1">
                  <a href>Olvidé mi contraseña</a>
                </v-col>
              </v-row>

              <v-btn
                rounded
                dark
                block
                x-large
                type="submit"
                class="btn-primary-shadow"
                color="primary"
                :loading="loading"
                >Entrar</v-btn
              >

              <v-col cols="12" md="auto" class="d-block d-md-none mt-6">
                <a href>Olvidé mi contraseña</a>
              </v-col>
            </v-form>
          </ValidationObserver>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data: () => ({
    email: "",
    password: "",
    remember: false,
    showPassword: false,
    loading: false,
  }),

  created() {
    let theme = localStorage.getItem("theme");
    let isTrueSet = theme == "true";
    this.$vuetify.theme.light = isTrueSet;
  },

  methods: {
    async Handlerlogin() {
      try {
        this.loading = true;
        const response = await this.$store.dispatch("auth/LOGIN", {
          email: this.email,
          password: this.password,
        });
        if (response.data.data.user.permissions.length > 0) {
          this.$snotify.success(
            "Nos encanta tenerte de vuelta.",
            "¡Bienvenido!"
          );
          this.$router.push({ name: "Dashboard" });
        } else {
          this.$snotify.warning(
            "No puedes ingresar en estos momentos",
            "¡Ups!"
          );
        }
      } catch (error) {
        this.$snotify.error(error.response.data.error.message, "¡Error!");
        // if (error.response.status == 401) {
        // }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.btn-accent-shadow {
  -webkit-box-shadow: 0px 12px 20px -10px rgba(88, 177, 83, 1) !important;
  -moz-box-shadow: 0px 12px 20px -10px rgba(88, 177, 83, 1) !important;
  box-shadow: 0px 12px 20px -10px rgba(88, 177, 83, 1) !important;
}

.btn-primary-shadow {
  -webkit-box-shadow: 0px 12px 20px -10px rgba(51, 50, 127, 1) !important;
  -moz-box-shadow: 0px 12px 20px -10px rgba(51, 50, 127, 1) !important;
  box-shadow: 0px 12px 20px -10px rgba(51, 50, 127, 1) !important;
}
</style>
