import { decentralized, product, Store } from "../../../services/httpclient";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async GET_COUNTRIES() {
    try {
      const response = await decentralized.get(`api/country`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_PROVISIONER(_, payload) {
    try {
      const response = await decentralized.get(
        `api/provisioner?country_id=${payload.country_id}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_STATES(_, payload) {
    try {
      const response = await decentralized.post(`api/country/state`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async REGISTER_COUNTRY(_, payload) {
    try {
      const response = await decentralized.post(`api/country/admin`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_COUNTRY(_, payload) {
    try {
      console.log("payload", payload);
      const response = await decentralized.put(
        `api/country/admin/${payload.id}`,
        payload.data
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_CURRENCIES() {
    try {
      const response = await product.get(`api/country/currencies`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async REGISTER_CURRENCY(_, payload) {
    try {
      const response = await product.post(`api/country/currencies`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_COTIZATIONS() {
    try {
      const response = await Store.get(`api/currencies/exchanges`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async UPDATE_COTIZATION(_, { id, ...payload }) {
    try {
      const response = await Store.patch(
        `api/currencies/exchanges/${id}`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

// exportar propiedades
export const country = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
