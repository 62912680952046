import { decentralized } from "../../../services/httpclient";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    async GET_STATUS() {
        try {
            const response = await decentralized.get('api/status');
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },
};

// exportar propiedades
export const status = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
