import { decentralized, product, Store } from "../../../services/httpclient";

const state = {
  deposits: [],
  deposits_time: [],
  deposits_all: [],
  paginate: {
    lastPage: 1,
    page: 1,
    perPage: 10,
    total: 0,
  },
  paginate_time_deposit: {
    lastPage: 1,
    page: 1,
    perPage: 10,
    total: 0,
  },
  deposit_name: "",
};

const getters = {
  GET_DEPOSITS: (state) => state.deposits,
  GET_PAGINATE: (state) => state.paginate,
  GET_PAGINATE_DEPOSIT: (state) => state.paginate_time_deposit,
  GET_DEPOSIT_NAME: (state) => state.deposit_name,
  GET_DEPOSIT_TIME: (state) => state.deposits_time,
  GET_DEPOSIT_ALL: (state) => state.deposits_all,
};

const mutations = {
  // ALL DEPOSIT WITHOUT PAGINATE
  SET_DEPOSITS_ALL: (state, payload) => {
    state.deposits_all = payload;
  },
  // DEPOSIT
  SET_DEPOSITS: (state, payload) => {
    state.deposits = payload;
  },
  PUSH_DEPOSIT: (state, payload) => {
    state.deposits.push(payload);
  },
  UPDATE_DEPOSITS: (state, payload) => {
    const indexupdate = state.deposits
      .map((element) => element.id)
      .indexOf(payload.id);

    if (indexupdate > -1) {
      state.deposits.splice(indexupdate, 1, payload);
    }
  },
  DELETE_DEPOSIT: (state, payload) => {
    const indexdelete = state.deposits
      .map((element) => element.id)
      .indexOf(payload);

    if (indexdelete > -1) {
      state.deposits.splice(indexdelete, 1);
    }
  },

  ADD_DEPOSITS_PAGINATE: (state, payload) => {
    state.paginate = payload;
  },

  // DEPOSIT TIME
  SET_DEPOSITS_TIME: (state, payload) => {
    state.deposits_time = payload;
  },

  PUSH_DEPOSIT_TIME: (state, payload) => {
    state.deposits_time.push(payload);
  },
  ADD_DEPOSITS: (state, payload) => {
    state.deposit_name = payload.name;
  },
  UPDATE_DEPOSITS_TIME: (state, payload) => {
    const indexupdate = state.deposits_time
      .map((element) => element.id)
      .indexOf(payload.id);
    if (indexupdate > -1) {
      state.deposits_time.splice(indexupdate, 1, payload);
    }
  },
  ADD_DEPOSITS_TIME_PAGINATE: (state, payload) => {
    state.paginate_time_deposit = payload;
  },
};

const actions = {
  async GET_DEPOSITS({ commit }, payload) {
    try {
      const response = await decentralized.get(
        `api/warehouse/?page=${payload.page}&per_page=${payload.per_page}&paginate=${payload.paginate}&country_id=${payload.country_id}&state_id=${payload.state_id}&location_id=${payload.location_id}`
      );

      console.log('response', response.data)

      const paginate = {
        lastPage: response.data.data.lastPage,
        page: response.data.data.page,
        perPage: response.data.data.perPage,
        total: response.data.data.total,
      };
      commit("ADD_DEPOSITS_PAGINATE", paginate);
      commit("SET_DEPOSITS", response.data.data.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_DEPOSITS_ALL({ commit }, payload) {
    try {
      const response = await decentralized.get(
        `api/warehouse/?paginate=${payload.paginate}`
      );
      commit(
        "SET_DEPOSITS_ALL",
        response.data.data.filter(
          (index) => parseInt(index.id) !== parseInt(payload.warehouse_start_id)
        )
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_DEPOSITS_STOCK(_, payload) {
    try {
      const response = await decentralized.get(
        `api/warehouse/stock_product?warehouse_id=${payload.origin_id}&page=${payload.page}&per_page=${payload.per_page}`
      );
      // const paginate = {
      //   lastPage: response.data.data.lastPage,
      //   page: response.data.data.page,
      //   perPage: response.data.data.perPage,
      //   total: response.data.data.total,
      // };
      // commit("products/ADD_PRODUCTS_PAGINATE", paginate, {root: true});
      // commit("products/SET_PRODUCTS", response.data.data.data, {root: true});
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_STATUS({ commit }, payload) {
    try {
      const response = await decentralized.get(
        `api/warehouse/active/${payload.id}`
      );
      commit("UPDATE_DEPOSITS", response.data.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async REGISTER_DEPOSITS({ dispatch }, payload) {
    try {
      const response = await decentralized.post("api/warehouse", payload);
      // commit("PUSH_DEPOSIT", response.data.data);

      const data = {
        page: 1,
        per_page: 10,
        paginate: true
      }
      dispatch("GET_DEPOSITS", data)
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_DEPOSITS({ commit }, payload) {
    try {
      const response = await decentralized.put(
        `api/warehouse/${payload.id}`,
        payload.data
      );
      commit("UPDATE_DEPOSITS", response.data.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async REGISTER_UPDATE_DEPOSITS_FREE({ commit }, payload) {
    try {
      const response = await decentralized.post("api/warehouse/free", payload);

      commit("UPDATE_DEPOSITS", response.data.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async REGISTER_DEPOSITS_TIME({ commit, dispatch }, payload) {
    try {
      const response = await decentralized.post("api/warehouse/time", payload);
      if (response.data.action == 0) {
        // commit("PUSH_DEPOSIT_TIME", response.data.data);
        const data = {
          warehouse_start_id: payload.warehouse_start_id,
          page: 1,
          per_page: 10,
          paginate: true
        }
        dispatch("GET_DEPOSITS_TIME", data)
      } else {
        commit("UPDATE_DEPOSITS_TIME", response.data.data)
      }
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_DEPOSITS_TIME({ commit }, payload) {
    try {
      const response = await decentralized.get(
        `api/warehouse/time?page=${payload.page}&per_page=${payload.per_page}&warehouse_start_id=${payload.warehouse_start_id}`
      );
      const paginate_time_deposit = {
        lastPage: response.data.data.data.lastPage,
        page: response.data.data.data.page,
        perPage: response.data.data.data.perPage,
        total: response.data.data.data.total,
      };
      commit("ADD_DEPOSITS_TIME_PAGINATE", paginate_time_deposit);
      commit("SET_DEPOSITS_TIME", response.data.data.data.data);
      commit("ADD_DEPOSITS", response.data.data.warehouse);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async SYNC_DEPOSITS(_, payload) {
    try {
      const response = await product.post(
        `api/warehouses/sync_contabilium`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_PRODUCT_DEPOSITS(_, payload) {
    try {
      const response = await decentralized.get(
        `api/warehouse/stock_product?warehouse_id=${payload.warehouse_id}&page=${payload.page}&per_page=${payload.per_page}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_PRODUCT_WAREHOUSE(_, payload){
    try {
      const response = await Store.patch(
        `/api/warehouses/products/${payload.product_in_warehouse_id}`,
        payload.data
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // PAISES

  async GET_COUNTRY() {
    try {
      const response = await decentralized.get(`api/country`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_STATE(_, payload) {
    try {
      const response = await decentralized.post(`api/country/state`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_LOCATIONS(_, payload) {
    try {
      const response = await decentralized.post(
        `api/country/location`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

// exportar propiedades
export const deposits = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
