import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { dashboard } from "@/store/modules/Dashboard/Dashboard";
import { deposits } from "@/store/modules/Deposits";
import { products } from "@/store/modules/Products";
import { status } from "@/store/modules/Status/status";
import { category } from "@/store/modules/Category";
import { feature } from "@/store/modules/Feature";
import { country } from "@/store/modules/Country";
import { b2bPublication } from "@/store/modules/B2bPublication";
import { store } from "@/store/modules/Store";
import { publications } from "@/store/modules/Publications";
import { auth } from "@/store/modules/Auth";
import { usersIndex } from "@/store/modules/Users";
import { orders } from "@/store/modules/Sales";
import { ml } from "@/store/modules/ML";
import { salesRetirement } from "@/store/modules/SalesRetirement";
import { stocks } from "@/store/modules/Stocks";
import { images } from "@/store/modules/Images";
import { traceability } from "./modules/Traceability";

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
    modules: {
        dashboard,
        deposits,
        products,
        status,
        category,
        country,
        store,
        publications,
        auth,
        usersIndex,
        orders,
        ml,
        salesRetirement,
        stocks,
        images,
        feature,
        b2bPublication,
        traceability
    },

    state: {
        drawer: null,
        splashScreen: false
    },

    getters: {
        splashScreen(state) {
            return state.splashScreen;
        },
        GET_DRAWER: (state) => state.drawer
    },

    mutations: {
        SETDRAWER(state, payload) {
            state.drawer = payload;
        },
        setSplashScreen(state, payload) {
            state.splashScreen = payload;
        }
    },

    actions: {},

    strict: debug
});
