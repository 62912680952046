import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// PLUGINS
import vuetify from "./plugins/vuetify";
import "./plugins/snotify";
import "./plugins/vee-validate";
import "./plugins/apex-chart";
import "./plugins/sweetAlert";
import "./plugins/cliport";
import "./plugins/rich-editor";

import { globalFunc } from './components/core/Utils/getUserPermissions';

Vue.prototype.$globalFunc = globalFunc;

// filtros
import filters from "./filters";
Vue.use(filters);

Vue.config.productionTip = false;
store.dispatch("auth/TRY_AUTO_LOGIN");

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount("#app");
