export const rutValidator = rut => {
  // Valida el rut con su cadena completa "XXXXXXXX-X"
  const regex = /^[0-9]{8}([0-9]|k|K){1}$/;
  if (!regex.test(rut)) {
    return false;
  }
  let n = Number(rut.slice(0, rut.length - 1));
  var M = 0,
    S = 1;
  for (; n; n = Math.floor(n / 10)) S = (S + (n % 10) * (9 - (M++ % 6))) % 11;
  const lastDigit = S ? S - 1 : "k";
  const currentLastDigit = rut.charAt(rut.length - 1);
  if (["k", "K"].includes(currentLastDigit)) {
    return lastDigit === currentLastDigit.toLowerCase();
  }
  return currentLastDigit == lastDigit;
};
