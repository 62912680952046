import {
  product,
  product_file,
  Store,
  Store_file
} from "../../../services/httpclient";

const state = {
  products: [],
  productsRentability: [],
  productsRentabilityAux: [],
  categories: [],
  specifications: [],
  paginate: {
    lastPage: 1,
    page: 1,
    perPage: 10,
    total: 0
  }
};

const getters = {
  GET_PRODUCTS: state => state.products,
  GET_PRODUCTS_RENTABILITY: state => state.productsRentability,
  GET_PAGINATE: state => state.paginate,
  GET_CATEGORIES: state => state.categories,
  GET_SPECIFICATIONS: state => state.specifications
};

const mutations = {
  SET_PRODUCTS: (state, payload) => {
    state.products = payload;
  },
  SET_PRODUCTS_RENTABILITY: (state, payload) => {
    state.productsRentability = payload;
    state.productsRentabilityAux = payload;
  },
  FILTER_PRODUCTS_RENTABILITY: (state, payload) => {
    const {
      sku,
      countryId,
      minRent,
      maxRent,
      status,
      only_catalog,
      only_cost_0,
      on_liquidate,
      on_auto_pause
    } = payload;
    let filterData = state.productsRentabilityAux;

    if (only_catalog) {
      filterData = filterData
        .map(p => {
          const { mercadolibre = [] } = p;

          return {
            ...p,
            flex: [],
            flex_b2b: [],
            mercadolibre: mercadolibre.filter(pub => pub.catalog_listing)
          };
        })
        .filter(p => p.mercadolibre.length > 0);
    }

    if (on_liquidate) {
      filterData = filterData
        .map(p => {
          const { mercadolibre = [] } = p;

          return {
            ...p,
            flex: [],
            flex_b2b: [],
            mercadolibre: mercadolibre.filter(
              pub => pub.liquidate === true && pub.catalog_listing
            )
          };
        })
        .filter(p => p.mercadolibre.length > 0);
    }
    if (on_auto_pause) {
      filterData = filterData
        .map(p => {
          const { mercadolibre = [] } = p;

          return {
            ...p,
            flex: [],
            flex_b2b: [],
            mercadolibre: mercadolibre.filter(
              pub => pub.allow_auto_pause === true
            )
          };
        })
        .filter(p => p.mercadolibre.length > 0);
    }

    if (only_cost_0) {
      filterData = filterData.map(p => {
        const { mercadolibre, flex, flex_b2b } = p;

        return {
          ...p,
          flex: flex.map(pub => ({
            ...pub,
            rentability: pub.rentability.filter(
              rent => rent.costs.product_cost === 0
            )
          })),
          mercadolibre: mercadolibre.map(pub => ({
            ...pub,
            rentability: pub.rentability.filter(
              rent => rent.costs.product_cost === 0
            )
          })),
          flex_b2b: flex_b2b.map(pub => ({
            ...pub,
            rentability: pub.rentability.filter(
              rent => rent.costs.product_cost === 0
            )
          }))
        };
      });
    }
    if (sku) {
      filterData = filterData.filter(p => {
        const { flex, flex_b2b, mercadolibre, name } = p;
        if (
          flex.some(({ product }) => product?.includes(sku)) ||
          mercadolibre.some(({ sku: mlSku }) => mlSku?.includes(sku)) ||
          flex_b2b.some(({ product }) => product?.includes(sku)) ||
          name?.toLowerCase().includes(sku?.toLowerCase())
        ) {
          return true;
        }
      });
    }

    if (countryId !== 4) {
      filterData = filterData.map(p => {
        const { flex, flex_b2b, mercadolibre } = p;

        return {
          ...p,
          flex: flex.filter(pub => pub.country.id === countryId),
          mercadolibre: mercadolibre.filter(
            pub => pub.country.id === countryId
          ),
          flex_b2b: flex_b2b.filter(pub => pub.country.id === countryId)
        };
      });
    }

    if (status !== "all") {
      filterData = filterData.map(p => {
        const { flex, flex_b2b, mercadolibre } = p;

        return {
          ...p,
          flex: flex.filter(pub =>
            status === "active" ? pub.is_active : !pub.is_active
          ),
          mercadolibre: mercadolibre.filter(pub =>
            status === "active" ? pub.is_active : !pub.is_active
          ),
          flex_b2b: flex_b2b.filter(pub =>
            status === "active" ? pub.is_active : !pub.is_active
          )
        };
      });
    }

    if (minRent) {
      filterData = filterData.map(p => {
        const { flex, flex_b2b, mercadolibre } = p;

        return {
          ...p,
          flex: flex.map(pub => ({
            ...pub,
            rentability: pub.rentability.filter(
              rent => rent.rentability_percentage >= minRent
            )
          })),
          mercadolibre: mercadolibre.map(pub => ({
            ...pub,
            rentability: pub.rentability.filter(
              rent => rent.rentability_percentage >= minRent
            )
          })),
          flex_b2b: flex_b2b.map(pub => ({
            ...pub,
            rentability: pub.rentability.filter(
              rent => rent.rentability_percentage >= minRent
            )
          }))
        };
      });
    }
    if (maxRent) {
      filterData = filterData.map(p => {
        const { flex, flex_b2b, mercadolibre } = p;

        return {
          ...p,
          flex: flex.map(pub => ({
            ...pub,
            rentability: pub.rentability.filter(
              rent => rent.rentability_percentage <= maxRent
            )
          })),
          mercadolibre: mercadolibre.map(pub => ({
            ...pub,
            rentability: pub.rentability.filter(
              rent => rent.rentability_percentage <= maxRent
            )
          })),
          flex_b2b: flex_b2b.map(pub => ({
            ...pub,
            rentability: pub.rentability.filter(
              rent => rent.rentability_percentage <= maxRent
            )
          }))
        };
      });
    }

    state.productsRentability = filterData.map(p => {
      const { flex, flex_b2b, mercadolibre } = p;
      return {
        ...p,
        flex: flex.filter(p => p.rentability.length > 0),
        flex_b2b: flex_b2b.filter(p => p.rentability.length > 0),
        mercadolibre: mercadolibre.filter(p => p.rentability.length > 0)
      };
    });
  },
  SET_CATEGORIES: (state, payload) => {
    state.categories = payload;
  },
  SET_SPECIFICATIONS: (state, payload) => {
    state.specifications = payload;
  },
  PUSH_PRODUCTS: (state, payload) => {
    state.products.push(payload);
  },
  UPDATE_PRODUCTS: (state, payload) => {
    const indexupdate = state.products
      .map(element => element.id)
      .indexOf(payload.id);

    if (indexupdate > -1) {
      state.products.splice(indexupdate, 1, payload);
    }
  },
  ADD_PRODUCTS_PAGINATE: (state, payload) => {
    state.paginate = payload;
  },

  DELETE_IMAGE: (state, payload) => {
    const indexupdate = state.products
      .map(element => element.id)
      .indexOf(payload.id_product);

    if (indexupdate > -1) {
      state.products[indexupdate].images.splice(payload.position_delete, 1);
    }
  }
};

const actions = {
  async GET_PRODUCTS({ commit }, payload) {
    try {
      const response = await product.get(
        `api/product/product?page=${payload.page}&per_page=${
          payload.per_page
        }&active=${payload.active}&sku=${
          payload?.sku ? payload.sku : ""
        }&search=${payload?.search ? payload.search : ""}&paginate=${
          payload.paginate
        }&country_id=${payload.country_id}&product_id=${
          payload?.product_id ? payload.product_id : ""
        }&name=${payload?.name ? payload.name : ""}${
          payload?.attention_required
            ? `&attention_required=${payload.attention_required}`
            : ""
        }`
      );
      const paginate = {
        lastPage: response.data.data.lastPage,
        page: response.data.data.page,
        perPage: response.data.data.perPage,
        total: response.data.data.total
      };
      commit("ADD_PRODUCTS_PAGINATE", paginate);
      commit("SET_PRODUCTS", response.data.data.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  FILTER_PRODUCTS_RENTABILITY({ commit }, filters) {
    commit("FILTER_PRODUCTS_RENTABILITY", filters);
  },
  async GET_PRODUCTS_RENTABILITY({ commit }) {
    try {
      const response = await Store.get(`api/publication/rentability`);
      commit("SET_PRODUCTS_RENTABILITY", response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async UPDATE_PUBLICATION(_, payload) {
    try {
      const { pubId, ...rest } = payload;
      const response = await Store.patch(
        `api/meli/publications/${pubId}`,
        rest
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async UPDATE_RENTABILITY_BOUNDS(_, payload) {
    const { id, ...rest } = payload;
    try {
      const response = await Store.patch(
        `api/publication/rentability/bounds/${id}`,
        rest
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_PRODUCTS_TRANSACTIONS(_, payload) {
    try {
      const response = await product.get(
        `api/product/product?page=${payload.page}&per_page=${payload.per_page}&active=${payload.active}&search=${payload.search}`
      );
      // const paginate = {
      //     lastPage: response.data.data.lastPage,
      //     page: response.data.data.page,
      //     perPage: response.data.data.perPage,
      //     total: response.data.data.total,
      // };
      // commit('ADD_PRODUCTS_PAGINATE', paginate);
      // commit('SET_PRODUCTS', response.data.data.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_STATUS({ state, dispatch }, payload) {
    try {
      const response = await product.post(
        `api/product/product_status/${payload.id}`,
        payload
      );
      // commit('UPDATE_PRODUCTS', response.data.data);
      const paginate = {
        page: state.paginate.page,
        per_page: state.paginate.perPage,
        active: payload.active
      };
      dispatch("GET_PRODUCTS", paginate);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async REGISTER_PRODUCTS({ dispatch }, payload) {
    try {
      const response = await product.post("api/product/product", payload);
      const paginate = {
        page: 1,
        per_page: state.paginate.perPage,
        active: true,
        search: ""
      };
      dispatch("GET_PRODUCTS", paginate);
      // commit('PUSH_PRODUCTS', response.data.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_PRODUCTS({ commit }, payload) {
    try {
      const response = await product.post(
        `api/product/product_update/${payload.id}`,
        payload.request
      );
      // const paginate = {
      //     page: state.paginate.page,
      //     per_page: state.paginate.perPage,
      //     active: true,
      //     search: ""
      // }
      // dispatch('GET_PRODUCTS', paginate);
      commit("UPDATE_PRODUCTS", response.data.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async DELETE_PRODUCT_IMAGE({ commit }, payload) {
    try {
      commit("DELETE_IMAGE", payload);
      const response = await product.post(
        `api/product/delete_image/${payload.id}`,
        payload.data
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // CONTABILIUM PRODUCTS
  async REGISTER_PRODUCT_CONTABILIUM({ dispatch }, payload) {
    try {
      const response = await product.post(
        `api/product/create_contabilium_sku`,
        payload
      );
      const paginate = {
        page: 1,
        per_page: state.paginate.perPage,
        active: true,
        search: ""
      };
      dispatch("GET_PRODUCTS", paginate);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async SYNC_CONTABILIUM({ dispatch }) {
    try {
      const response = await product.post(`api/product/sync_contabilium`);
      const paginate = {
        page: 1,
        per_page: state.paginate.perPage,
        active: true,
        search: ""
      };
      dispatch("GET_PRODUCTS", paginate);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async SYNC_CONTABILIUM_CL({ dispatch }) {
    try {
      const response = await product.post(`api/product/sync_contabilium_cl`);
      const paginate = {
        page: 1,
        per_page: state.paginate.perPage,
        active: true,
        search: ""
      };
      dispatch("GET_PRODUCTS", paginate);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // BRANDS, MODELS

  async GET_BRAND(_, payload) {
    try {
      const response = await product.get(
        `api/brand/admin?has_publications=${payload.has_publications}&has_publications_b2b=${payload.has_publications_b2b}&store_id=${payload.store_id}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async CREATE_BRAND(_, payload) {
    try {
      const response = await product.post(`api/brand/admin`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_BRAND(_, payload) {
    try {
      const response = await product.put(
        `api/brand/brand/${payload.id}`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_MODELS(_, payload) {
    try {
      const response = await product.post(`api/brand/models`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // UPLOAD FILE

  async UPLOAD_PRODUCTS(_, payload) {
    try {
      const response = await product_file.post(
        `api/product/product_import`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPLOAD_SHIP_DEPOSIT(_, payload) {
    try {
      const response = await Store_file.post(
        `api/product_warehouse/import_xlsx`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async EXPORT_SHIP_DEPOSIT() {
    try {
      const response = await Store_file.post(
        `api/product_warehouse/export_xlsx`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async PRODUCT_PRICE(_, payload) {
    try {
      const response = await product.get(
        `api/product/product_price?page=${payload.page}&per_page=${payload.per_page}&paginate=${payload.paginate}&country_id=${payload.country_id}&search=${payload.search}&group=${payload.group}&brand_id=${payload.brand_id}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_PRICE_PRODUCT(_, payload) {
    try {
      const response = await product.post(
        `api/product/product_partial_update`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_PRODUCT_ERP(_, request) {
    try {
      const { id: productErpId, ...payload } = request;
      const response = await product.patch(
        `api/product_erps/${productErpId}`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_DOC_MANUAL(_, payload) {
    try {
      const response = await product_file.post(
        `api/product/document_product/${payload.id.product_id}`,
        payload.data
      );

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_IMAGE_BRAND(_, payload) {
    try {
      const response = await product_file.post(
        `api/brand/image_update`,
        payload.data
      );

      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_GENERATE_SKU(_, payload) {
    try {
      const response = await product.get(
        `api/product/sku_generate?brand_id=${payload.brand_id}&product_id=${payload.product_id}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async SYNC_PRODUCTS(_, payload) {
    try {
      const response = await product.post(`api/product/sync_all_erp`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async INVOICE_ORDER(_, payload) {
    try {
      const response = await Store.post(`api/orders/order_invoice`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async INVOICE_ORDER_ML(_, payload) {
    try {
      const response = await Store.post(
        `api/payments/bill_draft_ml_arg`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async SYNC_PRICE_PRODUCT() {
    try {
      const response = await product.post(`api/warehouses/sync_stock_history`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_PUBLICATION_ML(_, payload) {
    try {
      const response = await product.get(
        `api/questions_answers_ml/publication_ml?page=${payload.page}&per_page=${payload.per_page}&paginate=${payload.paginate}&country_id=${payload.country_id}&auth_status=${payload.auth_status}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_USER_QUESTION_ML(_, payload) {
    try {
      const response = await product.get(
        `api/questions_answers_ml/users_questions?page=${payload.page}&per_page=${payload.per_page}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async REGISTER_TRANSACTION_PROVISIONER_WAREHOUSE(_, payload) {
    try {
      const response = await product.post(
        `api/transaction/provisioner_warehouse`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATED_TRANSACTION_PROVISIONER_WAREHOUSE(_, payload) {
    try {
      const response = await product.put(
        `api/transaction/provisioner_warehouse/${payload.id}`,
        payload.data
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_TRANSACTION_PROVISIONER_WAREHOUSE(_, payload) {
    try {
      const response = await product.get(
        `api/transaction/provisioner_warehouse?page=${payload.page}&per_page=${payload.per_page}&paginate=${payload.paginate}&search_product=${payload.search_product}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async REGISTER_ASSIGN_QUESTION(_, payload) {
    try {
      const response = await product.post(
        `api/questions_answers_ml/assign_question`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async SAVE_ANSWER(_, payload) {
    try {
      const response = await product.post(
        `api/questions_answers_ml/save_answers`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_CURRENT_STOCK(_, payload) {
    try {
      const response = await product.post(
        `api/transaction/update_current_stock`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // CATEGORIES

  async GET_CATEGORIES({ commit }, payload) {
    try {
      const queryParams = new URLSearchParams();
      if (payload.page) {
        queryParams.append("page", payload.page);
      }
      if (payload.per_page) {
        queryParams.append("per_page", payload.per_page);
      }
      if (payload.name) {
        queryParams.append("name", payload.name);
      }
      const queryString = queryParams.toString();
      const url = `api/product/categories${
        queryString ? `?${queryString}` : ""
      }`;

      const response = await product.get(url);
      const paginate = {
        lastPage: response.data.lastPage,
        page: response.data.page,
        perPage: response.data.perPage,
        total: response.data.total
      };
      commit("ADD_PRODUCTS_PAGINATE", paginate);
      commit("SET_CATEGORIES", response.data.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async REGISTER_CATEGORIES(_, payload) {
    try {
      const response = await product.post("api/product/categories", payload);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_CATEGORY(_, payload) {
    try {
      const response = await product.patch(
        `api/product/categories/${payload.product_category_id}`,
        payload.data
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async DELETE_CATEGORY(_, payload) {
    try {
      const response = await product.delete(
        `api/product/categories/${payload.product_category_id}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async CHECK_CATEGORY_DELETABLE(_, payload) {
    try {
      const response = await product.get(
        `/api/product/categories/${payload.product_category_id}/deletable`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async ADD_SPECIFICATIONS_TO_CATEGORY(_, payload) {
    try {
      const response = await product.post(
        `/api/product/categories/${payload.product_category_id}/specifications`,
        payload.data
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async DELETE_SPECIFICATIONS_TO_CATEGORY(_, payload) {
    try {
      const response = await product.delete(
        `/api/product/categories/${payload.product_category_id}/specifications`,
        { data: payload.data }
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async DELETE_CATEGORY_TO_PRODUCT(_, payload) {
    try {
      const response = await product.delete(
        `/api/product/${payload.product_id}/categories`,
        { data: payload.data }
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_SPECIFICATIONS({ commit }, payload) {
    try {
      const queryParams = new URLSearchParams();
      if (payload.page) {
        queryParams.append("page", payload.page);
      }
      if (payload.per_page) {
        queryParams.append("per_page", payload.per_page);
      }
      if (payload.paginate) {
        queryParams.append("paginate", `${payload.paginate}`);
      }
      if (payload.name) {
        queryParams.append("name", payload.name);
      }
      const queryString = queryParams.toString();
      const url = `api/product/categories/specifications${
        queryString ? `?${queryString}` : ""
      }`;

      const response = await product.get(url);
      if (payload.paginate) {
        const paginate = {
          lastPage: response.data.lastPage,
          page: response.data.page,
          perPage: response.data.perPage,
          total: response.data.total
        };
        commit("ADD_PRODUCTS_PAGINATE", paginate);
        commit("SET_SPECIFICATIONS", response.data.data);
      } else {
        commit("SET_SPECIFICATIONS", response.data);
      }
      return response.data.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async REGISTER_SPECIFICATIONS(_, payload) {
    try {
      const response = await product.post(
        "api/product/categories/specifications",
        payload
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_SPECIFICATION(_, payload) {
    try {
      const response = await product.patch(
        `api/product/categories/specifications/${payload.specification_id}`,
        payload.data
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async DELETE_SPECIFICATION(_, payload) {
    try {
      const response = await product.delete(
        `api/product/categories/specifications/${payload.specification_id}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async CHECK_SPECIFICATION_DELETABLE(_, payload) {
    try {
      const response = await product.get(
        `/api/product/categories/specifications/${payload.specification_id}/deletable`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async EXPORT_PRODUCTS() {
    try {
      const response = await product.get(`/api/product/export_products/`);
      const filename = `products-${new Date().valueOf()}.csv`;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

// exportar propiedades
export const products = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
