import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/auth/Login.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: Login
  },
  {
    path: "/admin",
    name: "AdminLayout",
    component: () => import("../views/layout/AdminLayout.vue"),
    meta: {
      requiresAuth: true
    },
    redirect: {
      name: "dashboard"
    },
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../views/app/Dashboard.vue"
          )
      },
      {
        path: "/roles",
        name: "Roles",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "@/components/Roles/indexRoles.vue"
          )
      },
      {
        path: "/usuarios",
        name: "Usuarios",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "@/components/Users/indexUsers.vue"
          )
      },
      {
        path: "/client-report",
        name: "Clientes",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Clients/indexClients.vue"
          )
      },
      {
        path: "/paises",
        name: "Paises",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Country/indexCountry.vue"
          )
      },
      {
        path: "/deposito-report",
        name: "Depositos",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../views/app/Deposito.vue"
          )
      },
      {
        path: "/deposito-productos",
        name: "Depositos-Productos",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "@/components/Deposits/DepositProducts/indexDepositProd.vue"
          )
      },
      {
        path: "/questions",
        name: "Preguntas sin responder",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "@/components/Questions/questions.vue"
          )
      },
      {
        path: "/question-history",
        name: "Historial",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "@/components/Questions/questionHistory.vue"
          )
      },
      {
        path: "/facturas-argentina",
        name: "Facturas Argentina",
        meta: {
          requiredPermission: false
        },
        component: () => import("../views/app/Facturas.vue"),
        props: {
          countryID: 1
        }
      },
      {
        path: "/facturas-chile",
        name: "Facturas Chile",
        meta: {
          requiredPermission: false
        },
        component: () => import("../views/app/Facturas.vue"),
        props: {
          countryID: 2
        }
      },
      {
        path: "/provedor-deposito",
        name: "Barco-Deposito",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "@/components/Transactions/ShipDepossit/indexShipDepost.vue"
          )
      },
      {
        path: "/deposito-time/:id",
        name: "Duración entre depositos",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Deposits/TimeDeposits/TimeDepositsIndex.vue"
          )
      },
      {
        path: "/transaccion",
        name: "Movimientos",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Transactions/IndexTransactions.vue"
          )
      },
      {
        path: "/registrar_transaccion",
        name: "Registrar Movimientos",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Transactions/Register.vue"
          )
      },
      {
        path: "/pro-report",
        name: "Productos",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Products/IndexProducts.vue"
          )
      },
      {
        path: "/user-profile",
        name: "Perfil",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/app/profile/ShowProfile.vue"
          )
      },
      {
        path: "/providers",
        name: "Proveedores",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Providers/indexProvider.vue"
          )
      },
      {
        path: "/categorias",
        name: "Categorias",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Category/indexCategory.vue"
          )
      },
      {
        path: "/monedas",
        name: "Monedas",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Currency/indexCurrency.vue"
          )
      },
      {
        path: "/tienda",
        name: "Tienda",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Shops/indexShop.vue"
          )
      },
      {
        path: "/publicaciones",
        name: "Publicaciones",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Publications/indexPublications.vue"
          )
      },
      {
        path: "/promociones",
        name: "Promociones",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Promotions/indexPromotions.vue"
          )
      },
      {
        path: "/carritos-abandonados",
        name: "Carritos abandonados",
        meta: {
          requiredPermission: false
        },
        component: () => import("../components/Cart/unsoldCart.vue"),
        props: {
          countryID: 1
        }
      },
      {
        path: "/blackList",
        name: "BlackList",
        meta: {
          requiredPermission: false
        },
        component: () => import("../components/BlackList/BlackList.vue")
      },
      {
        path: "/cupones",
        name: "Cupones",
        meta: {
          requiredPermission: true
        },
        component: () => import("../components/Coupon/indexCoupon.vue")
      },
      {
        path: "/ventas_borrador",
        name: "Ventas Borrador AR",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/SalesDraft/indexSales.vue"
          )
      },
      {
        path: "/ventas_borrador_chile",
        name: "Ventas Borrador CL",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/SalesDraftCL/indexSales.vue"
          )
      },
      {
        path: "/ventas_general",
        name: "Ventas General",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/SalesPrimary/indexSales.vue"
          )
      },
      {
        path: "/ventas_argentina",
        name: "Ventas Argentina",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/SalesAR/indexSales.vue"
          )
      },
      {
        path: "/ventas_chile",
        name: "Ventas Chile",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/SalesCL/indexSales.vue"
          )
      },
      {
        path: "/ventas_mexico",
        name: "Ventas Mexico",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/SalesMX/indexSales.vue"
          )
      },
      {
        path: "/ventas_cordoba",
        name: "Ventas Córdoba",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/SalesCBA/indexSales.vue"
          )
      },
      {
        path: "/ventas_rosario",
        name: "Ventas Rosario",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/SalesROS/indexSales.vue"
          )
      },
      {
        path: "/ventas_soporte",
        name: "Ventas Landing",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/SalesClient/indexSalesClient.vue"
          )
      },
      {
        path: "/envios",
        name: "Envios",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Shipping/indexShipping.vue"
          )
      },
      {
        path: "/envios_cordoba",
        name: "Envios Cordoba",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/ShippingCBA/indexShipping.vue"
          )
      },
      {
        path: "/envios_rosario",
        name: "Envios Rosario",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/ShippingROS/indexShipping.vue"
          )
      },
      {
        path: "/envios_chile",
        name: "Envios Chile",
        meta: { requiredPermission: true },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/ShippingCL/indexShipping.vue"
          )
      },
      {
        path: "/envios_mexico",
        name: "Envios Mexico",
        meta: { requiredPermission: true },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/ShippingMX/indexShipping.vue"
          )
      },
      {
        path: "/mercado_libre",
        name: "Mercado Libre",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/MercadoLibre/MercadoLibre.vue"
          )
      },
      {
        path: "/sales_retirement",
        name: "Retiro Cliente",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/SalesRetirement/indexSalesRetirement.vue"
          )
      },
      {
        path: "/sales_retirement_chile",
        name: "Retiro Cliente Chile",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/SalesRetirementCL/indexSalesRetirement.vue"
          )
      },
      {
        path: "/sales_retirement_cba",
        name: "Retiro Cordoba",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/SalesRetirementCBA/indexSalesRetirement.vue"
          )
      },
      {
        path: "/sales_retirement_ros",
        name: "Retiro Rosario",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/SalesRetirementROS/indexSalesRetirement.vue"
          )
      },
      {
        path: "/stock_valorizado",
        name: "Stock Valorizado",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Stocks/Valued.vue"
          )
      },
      {
        path: "/stock_proyectado",
        name: "Stock Proyectado",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Stocks/Projected.vue"
          )
      },
      {
        path: "/days_inventory",
        name: "Dias Inventario",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Stocks/DaysInventory.vue"
          )
      },
      {
        path: "/sales_weights",
        name: "Ventas Pesos",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Stocks/SalesWeights.vue"
          )
      },
      {
        path: "/product_price",
        name: "Precio Productos",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/PriceProduct/ProductPrice.vue"
          )
      },
      {
        path: "/publications-rent",
        name: "Rentabilidad publicaciones",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Products/ProductsRentabilityTable.vue"
          )
      },
      {
        path: "/notifications",
        name: "Notificaciones",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Notifications/Notifications.vue"
          )
      },
      {
        path: "/rotation",
        name: "Rotacion",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/InventoryRotation/InventoryRotation.vue"
          )
      },
      {
        path: "/product-categories",
        name: "Categorías de productos",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/ProductCategories/productCategories.vue"
          )
      },
      {
        path: "/product-specifications",
        name: "Especificaciones de las categorias",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/ProductSpecifications/productSpecifications.vue"
          )
      },
      {
        path: "/image_upload",
        name: "Cargar Imagen",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/ImageUpload/Index.vue"
          )
      },
      {
        path: "/caracteristicas",
        name: "Caracteristicas",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Features/indexCharacteristic.vue"
          )
      },
      {
        path: "/reserva_productos",
        name: "Reserva Productos",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/UserNotificationProduct/indexUserNotificationProduct.vue"
          )
      },
      {
        path: "/suscripciones",
        name: "Suscripciones",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/UserSubscription/index"
          )
      },
      {
        path: "/showroom_cl",
        name: "Showroom EDIFIER Chile",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Showroom/index"
          )
      },
      {
        path: "/control_de_rentabilidad",
        name: "Control de Rentabilidad",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Stocks/ProfitabilityControl.vue"
          )
      },

      {
        path: "/historico_stocks",
        name: "Historico de stocks",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Stocks/StockHistory.vue"
          )
      },

      {
        path: "/marcas",
        name: "Marcas",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Brand/indexBrand.vue"
          )
      },

      {
        path: "/transferencias",
        name: "Transferencias AR",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Transfer/indexTransfer.vue"
          )
      },

      {
        path: "/transferencias_chile",
        name: "Transferencias Chile",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/TransferCL/indexTransfer.vue"
          )
      },

      {
        path: "/b2b_registro_usuario",
        name: "Registro-Usuarios",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/B2B/Clients/IndexClients.vue"
          )
      },

      {
        path: "/b2b_publication",
        name: "B2b-Publication",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /*webpackChunkName: "admin"  */
            "../components/B2bPublication/indexPublicationB2b.vue"
          )
      },

      {
        path: "/b2b_clients",
        name: "B2b-Clientes",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /*webpackChunkName: "admin"  */
            "../components/ClientsB2B/indexClients.vue"
          )
      },

      {
        path: "/b2b_sales",
        name: "B2b-Ventas",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /*webpackChunkName: "admin"  */
            "../components/B2bSales/indexSales.vue"
          )
      },

      {
        path: "/preguntas_ml",
        name: "Preguntas ML",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /*webpackChunkName: "admin"  */
            "../components/QuestionML/index.vue"
          )
      },
      {
        path: "/ventas_no_completadas",
        name: "Ventas No Completadas",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/SalesNotCompleted/index.vue"
          )
      },
      {
        path: "/usuario_preguntas_ml",
        name: "Usuario Preguntas ML",
        meta: {
          requiredPermission: true
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/QuestionML/user.vue"
          )
      },
      {
        path: "/costos-varios",
        name: "Costos Varios",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Traceability/VariousCost/variousCosts"
          )
      },
      {
        path: "/inventarios-depositos",
        name: "Inventarios por Depósitos",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Traceability/Inventory/inventory.vue"
          )
      },
      {
        path: "/transferencia-inventario",
        name: "Transferencias de Inventario",
        meta: {
          requiredPermission: false
        },
        component: () =>
          import(
            /* webpackChunkName: "admin" */
            "../components/Traceability/Transfers/inventoryTransfer.vue"
          )
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  },
  base: process.env.BASE_URL,
  routes
});

// loading routes
router.afterEach(() => {
  setTimeout(() => {
    store.commit("setSplashScreen", false);
  }, 0);
});

// PROTECCION DE RUTAS
router.beforeEach(async (to, from, next) => {
  const authUser = store.getters["auth/AUTHENTICATED"];

  // Verifico Autenticacion
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!authUser) {
      next({ path: "/" });
    } else {
      // Verifico Permisos
      if (to.matched.some(route => route.meta.requiredPermission)) {
        const user = await store.dispatch("auth/GET_AUTH_USER");
        const userPermission = user.permissions.map(val => val.name);
        if (userPermission.includes(to.name)) {
          next();
        } else {
          router.push({ name: "Dashboard" }).catch(err => {
            err;
          });
        }
      } else {
        next();
      }
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
