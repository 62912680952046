import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
// import '@mdi/font/css/materialdesignicons.css';
// idioma
import es from "vuetify/es5/locale/es";

Vue.use(Vuetify);

export default new Vuetify({
    // icons: {
    //   iconfont: 'mdi'
    // },
    lang: {
        locales: { es },
        current: "es"
    },
    theme: {
        themes: {
            dark: {
                primary: "#7f7cc2",
                secondary: "#6366e6",
                accent: "#58B153",
                error: "#EF5350",
                info: "#64B5F6",
                success: "#66BB6A",
                warning: "#FFF176"
            },
            light: {
                primary: "#625FA4",
                secondary: "#6D71F9",
                accent: "#58B153",
                error: "#ff4444",
                info: "#33b5e5",
                success: "#00C851",
                warning: "#ffbb33"
            },
        },
        dark: false
    },
});
