import Vue from "vue";
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import vspanish from "vee-validate/dist/locale/es";
import { rutValidator } from "../utils/validators/rutValidator";
import { cuitValidator } from "../utils/validators/cuitValidator";
import { maxDiscountValidator } from "../utils/validators/maxDiscountValidator";

for (let rule in rules) {
  extend(rule, {
    ...rules[rule],
    message: vspanish.messages[rule]
  });
}
extend("rutValidation", {
  message: () => "El formato del campo RUT no es válido",
  validate: value => rutValidator(value)
});
extend("cuitValidation", {
  message: () => "El formato del campo CUIT no es válido",
  validate: value => cuitValidator(value)
});
extend("maxDiscount", {
  message: () => "Solo se puede agregar como maximo un 60% de descuento",
  validate: value => maxDiscountValidator(value)
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
