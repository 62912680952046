import Vue from "vue";
import Swal from "sweetalert2";

window.Swal = Swal;

/**
 * [CommentarySwal description]
 *
 * Ventana de confirmacion, con input para comentarios
 *
 * @param {String} title     [description]
 * @param {String} subTitle  [description]
 * @param {String} inputText [description]
 * @param {String} inputValue [description]
 *
 * @return {Promise<object>}
 */
function CommentarySwal(
    title,
    subTitle = "Confirmar Operacion",
    inputText = "Añadir un comentario... (Opcional)",
    inputValue = ""
) {
    return Swal.fire({
        title: title,
        text: subTitle,
        input: "textarea",
        inputValue: inputValue,
        inputPlaceholder: inputText,
        inputAttributes: {
            "aria-label": inputText
        },
        customClass: {
            // popup: "alert-card",
            title: "title-alert",
            content: "content-alert",
            actions: "actions-alert",
            confirmButton: "confirm-btn shadow-4"
        },
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar"
    });
}

/**
 * [ReadLaravelErrors description]
 * Solo para la lectura de respuesta de error
 * laravel api
 * Nota: no para otro caso de uso
 * @param {Object} errors [description]
 */
function ReadLaravelErrors(errors) {
    try {
        let list = "";
        for (const prop in errors) {
            // console.log(errors[prop][0]);
            list = list + `<li>${errors[prop][0]}</li>`;
        }

        Swal.fire({
            titleText: "¡Ups!",
            html: list,
            // type: "error",
            customClass: {
                popup: "round-small",
                confirmButton: "teal-btn"
            }
        });
    } catch (error) {
        console.warn(
            "el registro no corresponde a una respuesta error de laravel"
        );
    }
}

/**
 * [NotifyError description]
 * @param {string} title [description]
 */
function NotifySuccess(title) {
    try {
        Swal.fire({
            titleText: title,
            type: "success",
            customClass: {
                popup: "round-small",
                confirmButton: "teal-btn"
            }
        });
    } catch (error) {
        console.warn(error);
    }
}

/**
 * [NotifyError description]
 * @param {string} title [description]
 */
function NotifyError(title) {
    try {
        Swal.fire({
            titleText: title,
            // type: "error",
            customClass: {
                popup: "round-small",
                confirmButton: "teal-btn"
            }
        });
    } catch (error) {
        console.warn(
            "el registro no corresponde a una respuesta error de laravel"
        );
    }
}

/**
 * [SwalConfirm description]
 * @param {string} question    [description]
 * @param {string} description [description]
 * @return {Promise<any>}
 */
function SwalConfirm(question, description) {
    return Swal.fire({
        width: "370px",
        title: question,
        text: description,
        icon: "question",
        customClass: {
            popup: "alert-card",
            icon: "icon-alert",
            title: "title-alert",
            content: "content-alert",
            actions: "actions-alert",
            confirmButton: "confirm-btn shadow-4",
            cancelButton: "cancel-btn"
        },
        showCancelButton: true,
        reverseButtons: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar"
    });
}

Vue.prototype.$commentaryswal = CommentarySwal;
Vue.prototype.$laravelerrors = ReadLaravelErrors;
Vue.prototype.$notifysuccess = NotifySuccess;
Vue.prototype.$notifyerror = NotifyError;
Vue.prototype.$swalconfirm = SwalConfirm;
