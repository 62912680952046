import { users, Store } from "../../../services/httpclient";

const state = {
  paginate: {
    lastPage: 1,
    page: 1,
    perPage: 10,
    total: 0,
  },
  paginate_roles: {
    lastPage: 1,
    page: 1,
    perPage: 10,
    total: 0,
  },
};

const getters = {
  GET_PAGINATE: (state) => state.paginate,
  GET_PAGINATE_ROLES: (state) => state.paginate_roles,
};

const mutations = {
  ADD_PAGINATE: (state, payload) => (state.paginate = payload),
  ADD_PAGINATE_ROLES: (state, payload) => (state.paginate_roles = payload),
};

const actions = {
  async REGISTER_USER(_, request) {
    try {
      const response = await users.post(`api/users`, request);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_USERS({ commit }, payload) {
    try {
      const response = await users.get(
        `api/users/?page=${payload.page}&per_page=${payload.per_page}&paginate=${payload.paginate}`
      );
      const paginate = {
        lastPage: response.data.data.lastPage,
        page: response.data.data.page,
        perPage: response.data.data.perPage,
        total: response.data.data.total,
      };
      commit("ADD_PAGINATE", paginate);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_USERS(_, payload) {
    try {
      const response = await users.put(`api/users/${payload.id}`, payload.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_ROLES({ commit }, payload) {
    try {
      const response = await users.get(
        `api/roles/?page=${payload.page}&per_page=${payload.per_page}&paginate=${payload.paginate}`
      );
      const paginate = {
        lastPage: response.data.data.lastPage,
        page: response.data.data.page,
        perPage: response.data.data.perPage,
        total: response.data.data.total,
      };
      commit("ADD_PAGINATE_ROLES", paginate);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async REGISTER_ROLES(_, payload) {
    try {
      const response = await users.post(`api/roles/`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATED_ROLES(_, payload) {
    try {
      const response = await users.put(`api/roles/${payload.id}`, payload.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async INDEX_CLIENTS(_, payload) {
    try {
      const response = await users.get(
        `api/buyers?buyer_store=${payload.buyer_store}&store_id=${
          payload.store_id
        }&page=${payload.page}&per_page=${payload.per_page}&doc_number=${
          payload?.doc_number ? payload.doc_number : ""
        }&name=${payload?.name ? payload.name : ""}&email=${
          payload?.email ? payload.email : ""
        }&sku=${payload?.sku ? payload.sku : ""}&b2b_status=${
          payload.b2b_status
        }&days_shopping_cart=${payload.days_shopping_cart}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_CLIENTS(_, payload) {
    try {
      const response = await users.put(
        `api/buyers/update_admin/${payload.id}`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async INDEX_CLIENTS_B2B(_, payload) {
    try {
      const response = await users.get(
        `api/b2b/buyers?buyer_store=${payload.buyer_store}&store_id=${
          payload.store_id
        }&page=${payload.page}&per_page=${payload.per_page}&doc_number=${
          payload?.doc_number ? payload.doc_number : ""
        }&name=${payload?.name ? payload.name : ""}&email=${
          payload?.email ? payload.email : ""
        }&sku=${payload?.sku ? payload.sku : ""}&b2b_status=${
          payload.b2b_status
        }&days_shopping_cart=${payload.days_shopping_cart}&registration_status=${
          payload.registration_status}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_STATUS_CLIENT_B2B(_, payload) {
    try {
      const response = await users.patch(`api/buyer-stores/${payload.buyer_store_id}/b2b-status`, payload.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_EMAIL_LIST(_, payload) {
    try {
      const response = await Store.get(
        `/api/buyer-stores/${payload.buyer_store_id}/emails?page=${payload.page}&perPage=${payload.perPage}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_URL_EMAIL_FILE(_, payload) {
    try {
      const response = await Store.get(
        `/api/buyer-stores/emails/${payload.email_history_id}/download?filename=${payload.file_name}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_BUYER_SHOPPING_CART(_, payload) {
    try {
      const response = await Store.get(
        `/api/buyer-stores/${payload.buyer_store_id}/shopping-carts?page=${payload.page}&perPage=${payload.perPage}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_BUYER_SUBSCRIPTIONS(_, payload) {
    try {
      const response = await Store.get(
        `/api/buyer-stores/${payload.buyer_store_id}/stock-notifications`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_BLACKLIST(_, payload) {
    try {
      const response = await users.get(
        `/api/email-blacklist/?page=${payload.page}&perPage=${payload.perPage}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async REMOVE_EMAIL_FROM_BL(_, payload) {
    try {
      const response = await users.delete(`/api/email-blacklist`, {
        data: payload,
      });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async ADD_EMAIL_TO_BL(_, payload) {
    try {
      const response = await users.post(`/api/email-blacklist`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async UPDATE_PASSWORD(_, payload) {
    try {
      const response = await users.patch(`api/users/${payload.id}/password`, payload.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async BLOCK_USER(_, payload) {
    try {
      const response = await users.patch(`api/users/${payload.id}/toggle-active`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async DELETE_USER(_, payload) {
    try {
      const response = await users.delete(`api/users/${payload.id}`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

};

// exportar propiedades
export const usersIndex = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
