export const cuitValidator = (cuit) => {
  const regex = /^(20|23|24|27|30|33|34)[0-9]{9}$/;
  if (!regex.test(cuit)) {
    return false;
  }

  const [checkDigit, ...rest] = cuit
    .split("")
    .map(Number)
    .reverse();

  console.log(checkDigit);
  const total = rest.reduce(
    (acc, cur, index) => acc + cur * (2 + (index % 6)),
    0
  );

  const mod11 = 11 - (total % 11);

  if (mod11 === 11) {
    return checkDigit === 0;
  }

  if (mod11 === 10) {
    return false;
  }

  return checkDigit === mod11;
};
