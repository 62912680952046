// import { Store_file } from '../../../services/httpclient';
import { Store, Store_file } from "../../../services/httpclient";

const state = {
  notifications: []
};

const getters = {
  GET_NOTIFICATIONS: state => state.notifications
};

const mutations = {
  SET_NOTIFICATIONS: (state, payload) => {
    state.notifications = payload;
  }
};

const actions = {
  async REGISTER_PUBLICATIONS(_, payload) {
    try {
      const response = await Store_file.post(`api/publication`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async CREATE_OPEN_BOX_PUB(_, pub_id) {
    try {
      const response = await Store.get(`api/publication/open-box/${pub_id}`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATED_PUBLICATIONS({ dispatch }, payload) {
    try {
      const response = await Store_file.patch(
        `api/publication/${payload.id}`,
        payload.data
      );
      dispatch("GET_PUBLICATIONS");
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async DELETE_PUBLICATION(_, payload) {
    try {
      const response = await Store_file.delete(`api/publication/${payload.id}`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_PUBLICATIONS(_, payload) {
    try {
      const response = await Store.get(
        `api/publication/?page=${payload.page}&per_page=${payload.per_page}&name=${payload.name}&paginate=${payload.paginate}&sku=${payload.sku}&store_id=${payload.store_id}&sort_position=${payload.sort_position}&product_name=${payload.product_name}&is_active=${payload.is_active}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async PUBLICATIONS_ORDER(_, payload) {
    try {
      const response = await Store.post(
        `api/publication/position_update`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPLOAD_IMAGE(_, payload) {
    try {
      const response = await Store.post(
        `api/publication/upload_image/${payload.publication_id}`,
        payload.data
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_COUPON(_, payload) {
    try {
      const response = await Store.get(
        `api/coupon/?meli_id=${payload.meli_id}&page=${payload.page}&per_page=${payload.per_page}&paginate=${payload.paginate}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_PRODUCT_NOTIFICATION(_, payload) {
    try {
      const response = await Store.get(
        `api/user_product_notification/admin/?store_id=${payload.store_id}&page=${payload.page}&per_page=${payload.per_page}&paginate=${payload.paginate}&is_active=${payload.is_active}&sku=${payload.sku}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_USER_SUBSCRIPTIONS(_, payload) {
    try {
      const response = await Store.get(
        `api/subscriptions/admin/?store_id=${payload.store_id}&page=${payload.page}&per_page=${payload.per_page}&paginate=${payload.paginate}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_PRODUCT_NOTIFICATION(_, payload) {
    try {
      const response = await Store.put(
        `api/user_product_notification/update_active/${payload.id}`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async DELETE_PRODUCT_NOTIFICATION(_, payload) {
    try {
      const response = await Store.delete(
        `api/user_product_notification/admin/${payload.id}`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_FEATURES(_, payload) {
    try {
      const response = await Store.post(
        `api/publication/features_all`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPLOAD_IMAGE_CARRUSEL_PROMOTION(_, payload) {
    try {
      const response = await Store_file.post(`api/promotions/`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LIST_PROMOTIONS(_, payload) {
    try {
      const response = await Store.get(
        `api/promotions/?store_id=${payload.store_id}&type=${payload.type}&page=${payload.page}&per_page=${payload.per_page}&paginate=${payload.paginate}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async DELETE_PROMOTIONS(_, payload) {
    try {
      const response = await Store.delete(
        `api/promotions/${payload.promotion_id}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_PROMOTIONS(_, payload) {
    try {
      const response = await Store.patch(
        `api/promotions/${payload.dataId.id}`,
        payload.data
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async ADD_PUBLICATION_IMAGE(_, payload) {
    try {
      const response = await Store_file.post(
        `api/publication/add_image/${payload.id}`,
        payload.data
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async DELETE_PUBLICATION_IMAGE(_, payload) {
    try {
      const response = await Store.post(
        `api/publication/delete_image/${payload.id}`,
        payload.data
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATED_STATUS_PUBLICATIONS(_, payload) {
    try {
      console.log("**", payload);
      const response = await Store.post(
        `api/publication/update_active/`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async ENABLE_PUBLICATION(_, payload) {
    try {
      const response = await Store.patch(
        `/api/b2b/publications/${payload.publication_id}`,
        payload.request
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_PUBLICATION_VIEWS(_, payload) {
    try {
      const response = await Store.get(
        `api/buyer-stores/${payload.buyer_store_id}/publications-views?page=${payload.page}&perPage=${payload.perPage}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_NOTIFICATIONS({ commit }, params = {}) {
    try {
      const response = await Store.get(`api/publication-notifications/`, {
        params
      });
      commit("SET_NOTIFICATIONS", response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

// exportar propiedades
export const publications = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
