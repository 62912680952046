import { Store } from "../../../services/httpclient";

const state = {
  paginate: {
    lastPage: 1,
    page: 1,
    perPage: 10,
    total: 0,
  },
  dataOrder: [],
  statusShip: null,
  searchQuery: {
    page: 1,
    per_page: 10,
    paginate: true,
    shipping_mode: "",
    shipping_method_name: "",
    reqSeller: "",
    reqStore: "",
    reqSearchQuery: "",
    reqDateFrom: "",
    reqDateTo: "",
    reqSearchName: "",
    tabs_status: 1,
    reqOrderItems: "",
    open_box: false,
  },
};

const getters = {
  GET_PAGINATE: (state) => state.paginate,
  GET_DATA_ORDER: (state) => state.dataOrder,
  GET_DATA_STATUSHIP: (state) => state.statusShip,
  GET_DATA_FILTER: (state) => state.searchQuery,
};

const mutations = {
  SET_DATA: (state, payload) => {
    state.paginate = { ...payload.paginate };
    state.dataOrder = [...payload.dataOrder];
    // state.statusShip = payload.statusShip;
  },
  SET_STATUS_BAR: (state, payload) => (state.statusShip = payload),
  SET_SEARCH_QUERY: (state, payload) => {
    const methods_name =
      payload.shipping_method_name == "Todos"
        ? ""
        : payload.shipping_method_name;
    state.searchQuery.page = payload.page;
    state.searchQuery.per_page = payload.per_page;
    state.searchQuery.paginate = payload.paginate;
    state.searchQuery.shipping_mode = payload.shipping_mode;
    state.searchQuery.shipping_method_name =
      methods_name == null ? "" : methods_name;
    state.searchQuery.reqSeller = payload.reqSeller;
    state.searchQuery.reqStore =
      payload.reqStore == null ? "" : payload.reqStore;
    state.searchQuery.reqSearchQuery = payload.reqSearchQuery;
    state.searchQuery.reqDateFrom = payload.reqDateFrom;
    state.searchQuery.reqDateTo = payload.reqDateTo;
    state.searchQuery.reqSearchName = payload.reqSearchName;
    state.searchQuery.tabs_status = payload.tabs_status;
    state.searchQuery.reqOrderItems = payload.reqOrderItems;
    state.searchQuery.open_box = payload.open_box;
  },
  SET_PAGINATE(state, payload) {
    state.paginate.page = payload;
  },
  CLEAR_STATE(state) {
    state.paginate = {
      lastPage: 1,
      page: 1,
      perPage: 10,
      total: 0,
    };
    state.dataOrder = [];
    state.statusShip = null;
    state.searchQuery = {
      page: 1,
      per_page: 10,
      paginate: true,
      shipping_mode: "",
      shipping_method_name: "",
      reqSeller: "",
      reqStore: "",
      reqSearchQuery: "",
      reqDateFrom: "",
      reqDateTo: "",
      reqSearchName: "",
      tabs_status: 1,
    };
  },
};

const actions = {
  async GET_ORDERS(_, payload) {
    try {
      const response = await Store.post(`api/orders/`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_SHIP({ commit }, payload) {
    try {
      const response = await Store.post(`api/shippings`, payload);
      const paginate = {
        lastPage: response.data.data.data.lastPage,
        page: response.data.data.data.page,
        perPage: response.data.data.data.perPage,
        total: response.data.data.data.total,
      };
      const dataOrder = response.data.data.data.data.map((ship) => {
        let errorMessage = "";
        if (ship.response_error && !ship.tracking_number) {
          try {
            errorMessage = JSON.parse(ship.response_error).message;
          } catch (error) {
            errorMessage = ship.response_error;
          }
        }
        return { ...ship, errorMessage };
      });
      commit("SET_DATA", {
        paginate: { ...paginate, total: paginate.total || dataOrder.length },
        dataOrder,
      });
      commit("SET_STATUS_BAR", response.data.data.status_tabs);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_SHIP_SERCH({ dispatch, state }, payload) {
    try {
      const request = {
        page: 1,
        per_page: state.searchQuery.per_page,
        paginate: state.searchQuery.paginate,
        shipping_mode: state.searchQuery.shipping_mode,
        shipping_method_name:
          state.searchQuery.shipping_method_name == null
            ? ""
            : state.searchQuery.shipping_method_name,
        reqSeller: state.searchQuery.reqSeller,
        reqStore:
          state.searchQuery.reqStore == null ? "" : state.searchQuery.reqStore,
        reqSearchQuery: state.searchQuery.reqSearchQuery,
        reqDateFrom: state.searchQuery.reqDateFrom,
        reqDateTo: state.searchQuery.reqDateTo,
        reqSearchName: state.searchQuery.reqSearchName,
        tabs_status: state.statusShip,
        country_id: payload.country_id || 1,
        warehouse_id: payload.warehouse_id || 5,
        open_box: state.searchQuery.open_box,
      };
      // const request = {
      //     ...state.searchQuery
      // }
      dispatch("GET_SHIP", request);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async PRINT_LABEL({ dispatch }, payload) {
    try {
      const response = await Store({
        url: `api/shippings/ticket?url=${payload.path}&type=${payload.type}&id=${payload.id}`,
        method: "GET",
        responseType: "blob",
      });
      setTimeout(() => {
        dispatch("GET_SHIP_SERCH", payload);
      }, 500);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async INVOICE_ORDER(_, payload) {
    try {
      const response = await Store({
        url: `api/orders/invoice_download?file=${payload.file}`,
        method: "GET",
        responseType: "blob",
      });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async MOVE_TO_PREPARED({ dispatch }, payload) {
    try {
      const response = await Store.post(`api/shippings/prepeared`, payload);
      setTimeout(() => {
        dispatch("GET_SHIP_SERCH", payload);
      }, 500);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async MOVE_TO_TRANSIT({ dispatch }, payload) {
    try {
      const response = await Store.post(`api/shippings/transit`, payload);
      setTimeout(() => {
        dispatch("GET_SHIP_SERCH", payload);
      }, 500);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async MOVE_TO_DELIVERED({ dispatch }, payload) {
    try {
      const response = await Store.post(`api/shippings/delivered`, payload);
      setTimeout(() => {
        dispatch("GET_SHIP_SERCH", payload);
      }, 500);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_SELLER() {
    try {
      const response = await Store.get(`api/mercado_libre`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_STORE(_, payload) {
    try {
      const response = await Store.get(
        `api/store?page=${payload.page}&per_page=${payload.per_page}&name=${payload.name}&paginate=${payload.paginate}&b2b_active=${payload.b2b_active}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_TRANSFER(_, payload) {
    try {
      const response = await Store.get(
        `api/transfer/admin?page=${payload.page}&per_page=${
          payload.per_page
        }&status=${payload.status}&store_id=${
          payload.store_id
        }&search_operation_number=${payload.search_operation_number}&search=${
          payload.search
        }&country_id=${payload.country_id || ""}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_TRANSFER_STATUS(_, payload) {
    try {
      const response = await Store.post(
        `api/transfer/admin/upload_status`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async PRINT_INVOICE_DES(_, payload) {
    try {
      const response = await Store({
        url: `api/shippings/download_invoice?shipping_id=${payload.shipping_id}`,
        method: "get",
        responseType: "blob",
      });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async TO_PREPARED_ROS(_, payload) {
    try {
      const response = await Store.post(`api/shippings/prepeared`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_ORDERS_NOT_COMPLETED(_, payload) {
    try {
      const response = await Store.get(
        `api/orders_completed/not_completed?page=${payload.page}&per_page=${payload.per_page}&meli_order_id=${payload.meli_order_id}&store_id=${payload.store_id}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async REGISTER_PRODUCT(_, payload) {
    try {
      const response = await Store.post(`api/create_items`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async INVOICE_MP(_, payload) {
    try {
      const response = await Store.post(
        `api/orders_completed/invoice_mp`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async INVOICE_ML(_, payload) {
    try {
      const response = await Store.post(
        `api/orders_completed/invoice_ml`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async SHIPPING_STORE(_, payload) {
    try {
      const response = await Store.post(
        `api/orders_completed/shipping_store`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async SHIPPING_ML(_, payload) {
    try {
      const response = await Store.post(`api/create_shippings`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_SHOWROOM(_, payload) {
    try {
      const response = await Store.get(
        `api/show_room/admin?page=${payload.page}&per_page=${payload.per_page}&status=${payload.status}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_SHOWROOM(_, payload) {
    try {
      const response = await Store.put(
        `api/show_room/admin/${payload.id}`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async CREATE_CLIENT(_, payload) {
    const { flextd_country_id, ...rest } = payload;
    try {
      const response = await Store.post(
        `/api/contabilium/clients?flextd_country_id=${flextd_country_id}`,
        rest
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_COUNTRIES(_, payload) {
    try {
      const response = await Store.get(
        `/api/contabilium/countries?flextd_country_id=${payload.flextd_country_id}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_PROVINCES(_, payload) {
    try {
      const response = await Store.get(
        `api/contabilium/provinces?flextd_country_id=${payload.flextd_country_id}&contabilium_country_id=${payload.contabilium_country_id}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_CITIES(_, payload) {
    try {
      const response = await Store.get(
        `api/contabilium/cities?country_id=${payload.country_id}&contabilium_province_id=${payload.contabilium_province_id}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

// exportar propiedades
export const orders = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
