// import { PbxHttpClient } from "@/services/httpclient";

export const dashboard = {
    namespaced: true,

    state: {
        dashboardDayValues: [],
        dashboardMonthValues: []
    },

    getters: {
        GET_DASHBOARD_DAY(state) {
            return state.dashboardDayValues;
        },

        GET_DASHBOARD_MONTH(state) {
            return state.dashboardMonthValues;
        }
    },

    mutations: {
        SET_DASHBOARD_DAY(state, payload) {
            state.dashboardDayValues = payload;
        },

        SET_DASHBOARD_MONTH(state, payload) {
            state.dashboardMonthValues = payload;
        }
    },

    actions: {
        // async DASHBOARD_DAY_VALUES({ commit }) {
        //     try {
        //         const response = await PbxHttpClient.get("/dashboardDay");
        //         commit("SET_DASHBOARD_DAY", response.data);
        //         return response;
        //     } catch (error) {
        //         Promise.reject(error);
        //     }
        // },

        // async DASHBOARD_MONTH_VALUES({ commit }) {
        //     try {
        //         const response = await PbxHttpClient.get("/dashboard_Mounth");
        //         commit("SET_DASHBOARD_MONTH", response.data);
        //         return response;
        //     } catch (error) {
        //         Promise.reject(error);
        //     }
        // }
    }
};
