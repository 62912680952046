/**
 * filtros HORA
 */

/**
 * [Valor description]
 * @param {date} value [description]
 */
import moment from 'moment';
// import moment from 'moment-timezone';
export function hour(value) {
    return moment(value).locale('es').format("hh:mm");
}