import { Store, Store_file } from "../../../services/httpclient";

const state = {
  unsoldCarts: [],
  questions: [],
  paginate_questions: {
    lastPage: 1,
    page: 1,
    perPage: 10,
    total: 0,
  },
};

const getters = {
  GET_UNSOLD_CARTS: (state) => state.unsoldCarts,
  GET_QUESTIONS: () => state.questions,
  GET_PAGINATE_QUESTIONS: (state) => state.paginate_questions,
};

const mutations = {
  SET_UNSOLD_CARTS: (state, payload) => {
    state.unsoldCarts = payload;
  },
  SET_QUESTIONS: (state, payload) => {
    state.questions = payload;
  },
  SET_PAGINATE_QUESTIONS: (state, payload) => {
    state.paginate_questions = payload;
  },
};

const actions = {
  async REGISTER_STORE(_, request) {
    try {
      const response = await Store_file.post(`api/store`, request);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_STORE(_, payload) {
    try {
      const response = await Store_file.put(
        `api/store/${payload.id}`,
        payload.request
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_STORE(_, payload) {
    try {
      const response = await Store.get(
        `api/store?page=${payload.page}&per_page=${payload.per_page}&name=${
          payload?.name ? payload.name : ""
        }&store_id=${payload?.store_id ? payload.store_id : ""}&paginate=${
          payload.paginate
        }`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_UNSOLD_CART({ commit }, payload) {
    try {
      const response = await Store.get(
        `/api/shooping_carts/statistics/unsold?store_id=${payload.store_id}&fromDate=${payload.fromDate}&toDate=${payload.toDate}`
      );
      commit("SET_UNSOLD_CARTS", response?.data?.data?.reverse());
      return response;
    } catch (error) {
      commit("SET_UNSOLD_CARTS", []);
      return Promise.reject(error);
    }
  },

  async GET_QUESTIONS({ commit }, payload) {
    try {
      const response = await Store.get(
        `api/questions?status=${payload.status}&page=${payload.page}&per_page=${payload.per_page}&paginate=${payload.paginate}&country=${payload.countryId}`
      );
      const questions = response?.data?.data;
      const paginate = {
        lastPage: response.data.lastPage,
        page: response.data.page,
        perPage: response.data.perPage,
        total: response.data.total,
      };
      commit("SET_QUESTIONS", questions);
      commit("SET_PAGINATE_QUESTIONS", paginate);
      return questions;
    } catch (error) {
      commit("SET_QUESTIONS", []);
      return Promise.reject(error);
    }
  },

  async ANSWER_QUESTION(_, payload) {
    try {
      const request = { answer: payload.answer };
      const response = await Store.post(`api/questions/${payload.id}`, request);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_HELP_AI(_, payload) {
    try {
      const response = await Store.post(
        `/api/questions/chat/completion/`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

// exportar propiedades
export const store = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
