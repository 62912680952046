/**
 * filtros Moneda
 */

/**
 * [Valor description]
 * @param {number} value [description]
 */
 export function currency(value) {
    if (value) {
        const AMOUNT_FORMAT = new Intl.NumberFormat("es-AR", {
            currency: "ARS",
            style: "currency"
        }).format(value);
        return AMOUNT_FORMAT;
    } else {
        return ' ';
    }
}