export const globalFunc = {
    userList: (list) => {
        let arrayRoutes = [];
        const userList = list.map((val) => {
            if (val.group != undefined) {
                arrayRoutes.push(
                    val.routes.map((gro) => {
                        return { ...gro };
                    })
                );
            }
            return {
                ...val,
            };
        });

        const flatArray = arrayRoutes.flat();

        const userPermissionList = [...userList, flatArray].flat();

        return userPermissionList;

    }
}