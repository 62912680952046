import { Store, Store_file } from "../../../services/httpclient";

const state = {
  publications: [],
  pagination: {}
};

const getters = {
  GET_PUBLICATIONS(state) {
    return state.publications;
  },

  GET_PAGINATION(state) {
    return state.pagination;
  }
};

const mutations = {
  SET_PUBLICATIONS(state, payload) {
    state.publications = payload;
  },

  SET_PAGINATION(state, payload) {
    state.pagination = payload;
  }
};

const actions = {
  async GET_PUBLICATIONS_B2B({ commit }, payload) {
    try {
      const response = await Store.get(
        `api/b2b/publications/?page=${payload.page}&per_page=${payload.per_page}&paginate=${payload.paginate}&country_id=${payload.country_id}&sku=${payload.sku}&order_by=${payload.order_by}`
      );
      commit("SET_PUBLICATIONS", response.data.data);
      commit("SET_PAGINATION", {
        lastPage: response.data.lastPage,
        page: response.data.page,
        perPage: response.data.perPage,
        total: response.data.total
      });
      return response.data.data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async REGISTER_PUBLICATION_B2B(_, payload) {
    try {
      const response = await Store.post(`api/b2b/publications/`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_PUBLICATION_B2B(_, payload) {
    try {
      console.log("payload update", payload);
      const response = await Store.patch(
        `api/b2b/publications/${payload.id}`,
        payload.request
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async DELETE_PUBLICATION_B2B(_, payload) {
    try {
      const response = await Store.put(
        `api/b2b_publications/publication/destroy/${payload.id}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async DELETE_PURCHASE_OPTION(_, id) {
    try {
      const response = await Store_file.delete(
        `api/b2b/purchase-options/${id}`
      );
      return response;
    } catch (error) {
      this.$snotify.error(error.message || "Error inesperado");
      return Promise.reject(error);
    }
  },

  async CREATE_PURCHASE_OPTION(_, payload) {
    try {
      const response = await Store.post(`api/b2b/purchase-options`, payload);
      return response;
    } catch (error) {
      this.$snotify.error(error?.message || "Error inesperado");
      return Promise.reject(error);
    }
  },
  async CALC_NET_PVC(
    _,
    {
      product_erp_id,
      iva,
      pvp_discount_percentage,
      pvc_variation,
      option_discount_percentage,
      use_cost_for_base_price,
      sale_margin_percentage
    }
  ) {
    try {
      const response = await Store.get(
        `api/b2b/purchase-options/net-pvc/?use_cost_for_base_price=${use_cost_for_base_price}&iva=${iva}&pvp_discount_percentage=${pvp_discount_percentage}&pvc_variation=${pvc_variation}&option_discount_percentage=${option_discount_percentage}&product_erp_id=${product_erp_id}&sale_margin_percentage=${sale_margin_percentage}`
      );
      return response;
    } catch (error) {
      this.$snotify.error(error?.message || "Error inesperado");
      return Promise.reject(error);
    }
  }
};

// exportar propiedades
export const b2bPublication = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
