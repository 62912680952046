import {
  decentralized,
  product,
  product_file,
  Store,
  Store_file,
  users,
} from "../../../services/httpclient";

const state = {
  token: null,
  user: null,
};

const getters = {
  AUTHENTICATED: (state) => (state.token != null ? true : false),
  GET_USER: (state) => state.user,
};

const mutations = {
  ADD_TOKEN: (state, payload) => {
    state.token = payload;
    localStorage.setItem("token", payload);
    users.defaults.headers.common["Authorization"] = `Bearer ${payload}`;
    Store.defaults.headers.common["Authorization"] = `Bearer ${payload}`;
    Store_file.defaults.headers.common["Authorization"] = `Bearer ${payload}`;
    decentralized.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${payload}`;
    product.defaults.headers.common["Authorization"] = `Bearer ${payload}`;
    product_file.defaults.headers.common["Authorization"] = `Bearer ${payload}`;
  },

  ADD_USER: (state, payload) => (state.user = payload),

  CLEAR_DATA: (state) => {
    state.token = null;
    state.user = null;
    localStorage.clear();
  },
};

const actions = {
  async LOGIN({ commit }, payload) {
    try {
      const response = await users.post(`api/auth/login/users`, payload);
      if (response.data.data.user.permissions.length > 0) {
        commit("ADD_TOKEN", response.data.data.access_token.token);
      }
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_AUTH_USER({ commit, state, getters }) {
    try {
      if (state.user == null) {
        const response = await users.get(`api/auth/user`);
        commit("ADD_USER", response.data.data);
        return response.data.data;
      } else {
        return getters.GET_USER;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_AUTH_USER_AGAIN({ commit }) {
    try {
      const response = await users.get(`api/auth/user`);
      commit("ADD_USER", response.data.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async TRY_AUTO_LOGIN({ commit }) {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        commit("ADD_TOKEN", token);
        // dispatch("GET_AUTH_USER");
        return;
      } else {
        return false;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async LOGOUT({ commit }) {
    try {
      const response = await users.get(`api/auth/logout`);
      commit("CLEAR_DATA");
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async AUTH_ML(_, payload) {
    try {
      const response = await Store.get(
        `api/meli/register?code=${payload.code}&country_id=${payload.country_id}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async REGISTER_CLIENTE(_, payload) {
    try {
      const response = await users.post("api/buyers", payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_CLIENTE(_, payload) {
    try {
      const response = await users.put(
        `api/buyers/update_admin/${payload.id}`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_ROLE_USER() {
    try {
      const response = await users.get(`api/users/user_role`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  GET_USER_LIST(_, payload) {
    return new Promise((resolve, reject) => {
      let arrayRoutes = [];
      const userList = payload.map((val) => {
        if (val.group != undefined) {
          arrayRoutes.push(
            val.routes.map((gro) => {
              return { ...gro };
            })
          );
        }
        return {
          ...val,
        };
      });

      const flatArray = arrayRoutes.flat();

      const userPermissionList = [...userList, flatArray].flat();

      if (userPermissionList.length > 0) {
        resolve(userPermissionList);
      } else {
        reject("error");
      }
    });
  },
};

// exportar propiedades
export const auth = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
