import { Store, Store_file } from "../../../services/httpclient";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async GET_PHOTOS(_, payload) {
    try {
      const response = await Store.get(
        `api/photos/admin/?page=${payload.page}&per_page=${payload.per_page}`
      );

      // const paginate = {
      //   lastPage: response.data.data.lastPage,
      //   page: response.data.data.page,
      //   perPage: response.data.data.perPage,
      //   total: response.data.data.total,
      // };
      // commit("ADD_DEPOSITS_PAGINATE", paginate);
      // commit("SET_DEPOSITS", response.data.data.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATED_PHOTOS(_, payload) {
    try {
      const response = await Store_file.post(`api/photos/image_update`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async REGISTER_PHOTOS(_, payload) {
    try {
      const response = await Store_file.post(`api/photos/admin`, payload);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async DELETE_PHOTOS(_, payload) {
    try {
      const response = await Store_file.delete(`api/photos/admin/${payload.id}`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

// exportar propiedades
export const images = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
