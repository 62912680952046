import { product } from '../../../services/httpclient';

const state = {};

const getters = {};

const mutations = {};

const actions = {
    async GET_FEATURES() {
        try {
            const response = await product.get(`api/features/admin`);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async REGISTER_FEATURES(_, payload) {
        try {
            const response = await product.post(`api/features/admin`, payload);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async UPDATED_FEATURES(_, payload) {
        try {
            const response = await product.put(`api/features/admin/${payload.id}`, payload.data);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async DELETE_FEATURES(_, payload) {
        try {
            const response = await product.delete(`api/features/admin/${payload.id}`);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },
};


// exportar propiedades
export const feature = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
