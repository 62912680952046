import { Store, Store_file } from "../../../services/httpclient";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    async GET_SALES_RETIREMENT(_, payload) {
        try {
            const response = await Store.get(
                `/api/shippings/customer_withdrawal?downloaded=${payload.downloaded}&page=${payload.page}&per_page=${payload.per_page}&paginate=${payload.paginate}&warehouse_id=${payload.warehouse_id}&country_id=${payload.country_id}&meli_id=${payload.meli_id}`,
                payload
            );
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async PRINT_SALES_RETIREMENT(_, payload) {
        try {
            const response = await Store({
                url: `/api/shippings/download_invoice`,
                method: 'POST',
                data: payload,
                responseType: 'blob',
            });
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async GET_SALES_RETIREMENT_CLIENT(_, payload) {
        try {
            const response = await Store.post(
                `/api/shippings/sales_customer_support?status=${payload.status}&page=${payload.page}&per_page=${payload.per_page}&paginate=${payload.paginate}&meli_id=${payload.meli_id}&product_sku=${payload.product_sku}&buyer_name=${payload.buyer_name}&date_ini=${payload.date_ini}&date_end=${payload.date_end}&country_id=${payload.country_id}`,
                payload
            );
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async UPLOAD_FILE(_, payload) {
        try {
            const response = await Store_file.post(
                `api/shippings/upload_tag`,
                payload
            );
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async UPLOAD_FILE_BILL(_, payload) {
        try {
            const response = await Store_file.post(
                `api/shippings/upload_invoice`,
                payload
            );
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },


    async SEND_EMAIL(_, payload) {
        try {
            const response = await Store.post(
                `api/shippings/email_tag`,
                payload
            );
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async PRINT_LABEL(_, payload) {
        try {
            const response = await Store({
                url: `api/shippings/ticket_order?url=${payload.url}&type=${payload.type}&order_id=${payload.id}`,
                method: 'GET',
                responseType: 'blob',
            });
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async PRINT_INVOICE(_, payload) {
        try {
            const response = await Store({
                url: `api/shippings/ticket?url=${payload.url}&type=${payload.type}`,
                method: 'GET',
                responseType: 'blob',
            });
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },
};

// exportar propiedades
export const salesRetirement = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
