import { Store, product } from "../../../services/httpclient";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async GET_ML_ACCOUNT() {
    try {
      const response = await Store.get(`api/mercado_libre`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_MELI_BRAND(_, payload) {
    try {
      const response = await product.get(
        `api/brand/meli_brand?mercado_libre_id=${payload.id}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async SAVE_DEFAULT_ANSWER(_, payload) {
    try {
      const response = await product.post(
        `api/default_answer/save_answer`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async UPDATE_DEFAULT_ANSWER(_, payload) {
    try {
      const response = await product.put(
        `api/default_answer/${payload.id}`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async DELETE_DEFAULT_ANSWER(_, payload) {
    try {
      const response = await product.delete(`api/default_answer/${payload.id}`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_DEFAULT_ANSWER(_, payload) {
    try {
      const response = await product.get(
        `api/default_answer?page=${payload.page}&per_page=${payload.per_page}&paginate=${payload.paginate}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async ORDER_DEFAULT_ANSWER(_, payload) {
    try {
      const response = await product.post(
        `api/default_answer/order_answers`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_DISPATCH_CODES(_, payload) {
    try {
      const response = await Store.get(
        `api/mercado_libre/dispatch-code?account_id=${payload.accountId}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async UPDATE_DISPATCH_CODES(_, payload) {
    const { id, ...rest } = payload;
    try {
      const response = await Store.patch(
        `api/mercado_libre/dispatch-code/${id}`,
        rest
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

// exportar propiedades
export const ml = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
