<template>
  <v-app>
    <v-fade-transition>
      <div class="splash-screen" v-if="splashScreen">
        <img src="./assets/logo.png" class="logo" alt="logo" />
      </div>
    </v-fade-transition>
    <router-view />

    <!-- NOTIFICACIONES -->
    <vue-snotify></vue-snotify>
  </v-app>
</template>

<script>
import Ws from "@adonisjs/websocket-client";

export default {
  name: "App",
  data: () => ({}),

  created() {
    this.initSocketProduct();
  },

  computed: {
    splashScreen() {
      return this.$store.getters.splashScreen;
    },
  },

  methods: {
    async initSocketProduct() {
      const inputToken = localStorage.getItem("token");

      /**
       * Si no esta loqgueado
       */
      if (inputToken == null) return;

      const ws = Ws("wss://productos.flextd.com", { reconnection: true });
      // const ws = Ws("ws://127.0.0.1:3335", { reconnection: false });

      // ws.connect()

      ws.withApiToken(inputToken).connect(); // token string
      window.client_token = inputToken;

      window.ws = ws;

      console.log("++++++++", ws);

      // const chat = ws.subscribe("chat")

      // connect to a non-dynamic channel,
      // you will receive notifications from all users,
      // it must be blocked from the backend
      // const notifications = ws.subscribe('notifications') // no

      // join the channel, and register a topic for the current user,
      // it remains to define how to uniquely identify the channels
      // const notify_key = "key_" + this.random(0, 500); // key_userId

      ws.on("open", () => {
        console.log("open socket");
      });

      ws.on("close", () => {
        console.log("close socket");
      });

      const notify = ws.subscribe("notify");
      window.notifyws = notify;

      notify.on("message", async (message) => {
        console.log("notify: receive message", message);
      });

      notify.on("ready", () => {
        console.log("notify: subscribe ready");
        // notifications.emit('message', 'hello')
      });

      notify.on("error", (error) => {
        console.log("notify: subscribe error");
        console.log(error);
      });

      notify.on("close", () => {
        console.log("notify: subscribe closed");
      });
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;800&display=swap");
@import "./assets/styles/index.scss";
@import "~vue-wysiwyg/dist/vueWysiwyg.css";

#app {
  font-family: "Poppins", sans-serif !important;
  word-break: normal !important;
  line-height: normal !important;
}

.theme--light.v-application {
  background: #eff0f8 !important;
  // background: #e8e7e7 !important;
  color: #495057 !important;
}

.title-text {
  font-size: 22px !important;
}

.subtitle-text {
  font-size: 16px !important;
}

.text-light {
  font-weight: 300;
}

.text-regular {
  font-weight: 400;
}

.text-bold {
  font-weight: 450;
}

.font-caption {
  font-size: 12px !important;
}

.bg-card-light {
  background: #eff0f8 !important;
}

.bg-card-dark {
  background: #414142 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
