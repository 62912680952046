/**
 *
 */

import { currency  } from './currency';
import { date  } from './date';
import { hour  } from './hour';

// crear plugin vue

export default {

  install(Vue) {
    Vue.filter('currency', currency);
    Vue.filter('date', date);
    Vue.filter('hour', hour);
  },
};