import { product, Store } from "../../../services/httpclient";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async GET_STOCKS(_, payload) {
    try {
      const response = await product.get(
        `api/projections/inventory_projections?page=${payload.page}&per_page=${payload.per_page}&sku=${payload.sku}&name=${payload.name}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_STOCKS_VALORIZED(_, payload) {
    try {
      const response = await product.get(
        `api/projections/valorized_stock_projections?page=${payload.page}&per_page=${payload.per_page}&sku=${payload.sku}&days=${payload.days}&warehouses=${payload.warehouses}&country_id=${payload.country_id}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_STOCKS_DAYS(_, payload) {
    try {
      const response = await product.get(
        `api/projections/stock_days?country_id=${payload.country_id}&days_sale=${payload.days_sale}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_SALES_WEIGHTS(_, payload) {
    try {
      const response = await Store.get(
        `api/store/money_collected?country_id=${payload.country_id}&date_from=${payload.date_from}&date_to=${payload.date_to}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_PROFITABILITY_CONTROL(_, payload) {
    try {
      const response = await Store.get(
        `api/control/profitability_control?page=${payload.page}&per_page=${payload.per_page}&month=${payload.month}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_HISTORY_STOCKS(_, payload) {
    try {
      const response = await product.get(
        `api/projections/stock_history?page=${payload.page}&per_page=${payload.per_page}&sku=${payload.sku}&days=${payload.days}&warehouses=${payload.warehouses}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async GET_STOCK_PROYECTION(_, payload) {
    try {
      const {
        country,
        proyectStockOnTransit,
        proyectStockOnHand,
        brands
      } = payload;
      const response = await Store.get(
        `/api/inventories/rotation?country=${country}&proyectStockOnHand=${proyectStockOnHand ||
          ""}&proyectStockOnTransit=${proyectStockOnTransit ||
          ""}&brands=${brands}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async EXPORT_PROYECTIONS(_, payload) {
    try {
      const {
        country = 3,
        proyectStockOnTransit,
        proyectStockOnHand,
        brands
      } = payload;
      const response = await Store.get(
        `/api/inventories/rotation/csv/?country=${country}&proyectStockOnHand=${proyectStockOnHand ||
          ""}&proyectStockOnTransit=${proyectStockOnTransit ||
          ""}&brands=${brands}`
      );
      const filename = `proyections-${new Date().valueOf()}.csv`;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

// exportar propiedades
export const stocks = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
