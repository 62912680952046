import { product, product_file } from '../../../services/httpclient';

const state = {};

const getters = {};

const mutations = {};

const actions = {
    async GET_CATEGORIES(_, payload) {
        try {
            const response = await product.get(`api/categories?page=${payload.page}&per_page=${payload.per_page}&name=${payload.name !== '' ? payload.name : ''}&store_id=${payload.store_id !== '' ? payload.store_id : ''}&paginate=${payload.paginate}`);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async REGISTER_CATEGORIES(_, payload) {
        try {
            const response = await product.post(`api/categories`, payload);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async UPDATED_CATEGORIES(_, payload) {
        try {
            const response = await product.put(`api/categories/${payload.id}`, payload.data);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async FILTER_CATEGORY(_, payload) {
        try {
            const response = await product.get(`api/categories?name=${payload.name}`);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async DELETE_CATEGORY(_, payload) {
        try {
            const response = await product.delete(`api/categories/${payload.id}`);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async DELETE_SUB_CATEGORY(_, payload) {
        try {
            console.log('entre store', payload)
            const response = await product.delete(`api/categories/sub_categories/${payload.id}`);
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    },

    async UPDATE_IMAGE(_, payload) {
        try {
          const response = await product_file.post(
            `api/categories/update_image`,
            payload.data
          );
          
          return response;
        } catch (error) {
          return Promise.reject(error);
        }
      },
};


// exportar propiedades
export const category = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
