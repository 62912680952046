import axios from "axios";
import router from "../router";
import storeVuex from "../store";

/**
 * [cliente env url/api]
 */

export const decentralized = axios.create({
  baseURL: process.env.VUE_APP_DECENTRALIZED,
  // timeout: 2000,
  headers: {
    "Access-Control-Allow-Origin": process.env.VUE_APP_DECENTRALIZED,
  },
});

export const product = axios.create({
  baseURL: process.env.VUE_APP_PRODUCT,
  // timeout: 2000,
  headers: {
    "Access-Control-Allow-Origin": process.env.VUE_APP_PRODUCT,
  },
});

export const product_file = axios.create({
  baseURL: process.env.VUE_APP_PRODUCT,
  headers: {
    Accept: "multipart/form-data",
    "Access-Control-Allow-Origin": process.env.VUE_APP_PRODUCT,
  },
});

export const Store = axios.create({
  baseURL: process.env.VUE_APP_STORE,
  // timeout: 2000,
  headers: {
    "Access-Control-Allow-Origin": process.env.VUE_APP_STORE,
  },
});

export const Store_file = axios.create({
  baseURL: process.env.VUE_APP_STORE,
  headers: {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": process.env.VUE_APP_STORE,
  },
});

export const users = axios.create({
  baseURL: process.env.VUE_APP_USERS,
  // timeout: 2000,
  headers: {
    "Access-Control-Allow-Origin": process.env.VUE_APP_STORE,
  },
});

users.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    console.log(error.response.data);
    if (error.response.status === 401) {
      console.log("401 response");
      storeVuex.commit("auth/CLEAR_DATA");
      router.push({ name: "login" });
    }
    return Promise.reject(error);
  }
);
