/**
 * filtros Fecha
 */

/**
 * [Valor description]
 * @param {date} value [description]
 */
// import moment from 'moment';
import moment from 'moment-timezone';
// const moment = require('moment-timezone');
export function date() {
    // return moment(value).locale("es").format("DD-MM-YYYY");
    return moment().tz('America/Caracas').format('DD-MM-YYYY')
}