import {
  Store,
  Store_file,
  decentralized,
  product
} from "../../../services/httpclient";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async GET_LIST_INVENTORY_TRANSFERS() {
    try {
      const response = await Store.get(`api/inventory-transfers/`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_INVENTORY_TRANSFERS(_, payload) {
    try {
      const response = await Store.get(`api/inventory-transfers/${payload}`);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_RECEIPT_URL(_, payload) {
    try {
      const response = await Store.get(
        `api/inventory-transfers/receipts/download/${payload}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async CONFIRM_RECEIPT(_, payload) {
    try {
      const response = await Store.get(
        `api/inventory-transfers/confirm/${payload}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async CANCEL_TRANSFER(_, payload) {
    try {
      const response = await Store.post(
        `api/inventory-transfers/cancel/${payload}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async CREATE_INVENTORY_TRANSFERS(_, payload) {
    try {
      const response = await Store_file.post(
        `api/inventory-transfers`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async UPDATE_COST(_, payload) {
    const { id, ...rest } = payload;
    try {
      const response = await Store.patch(
        `api/inventory-transfers/costs/${id}`,
        rest
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async ADD_NEW_COST(_, payload) {
    const { transferId, ...rest } = payload;
    try {
      const response = await Store.post(
        `api/inventory-transfers/${transferId}/costs`,
        rest
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async UPDATE_INVENTORY_TRANSFERS(_, payload) {
    try {
      const id = payload.get("id");
      const response = await Store_file.patch(
        `api/inventory-transfers/${id}`,
        payload
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_PRODUCT_TO_TRANSFER(_, payload) {
    try {
      const response = await decentralized.get(
        `api/warehouse/stock_product?warehouse_id=${payload.warehouse_id}&page=${payload.page}&per_page=${payload.per_page}&sku=${payload.sku}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_PRODUCT_TO_ENTRY(_, payload) {
    try {
      const response = await product.get(
        `api/product/product?page=${payload.page}&per_page=${payload.per_page}&active=${payload.active}&sku=${payload.sku}&paginate=${payload.paginate}&country_id=${payload.countryId}`
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

// exportar propiedades
export const traceability = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
